$light-primary-text: #fff;
$dark-primary-text: #000;

$luma-navbar: #263541;
$luma-navbar-text: #9FA5AB;

$luma-brand-gray: #9B9B9B;
$luma-brand-green: #C6DA7C;
$luma-brand-bright-green: #CBE880;
$luma-brand-hover-bright-green: #e4fba9;
$luma-brand-aqua: #4ECDC4;
$luma-brand-skyblue:#12a9e3;
$luma-brand-yellow: #F9CC22;
$luma-brand-orange: #F0A202;
$luma-brand-purple: #9e70eb;
$luma-alert-red: #D0021B;
$luma-alert-green: #99AB55;
$luma-alert-orange: #F5A623;
$luma-status-green: #c6da7c;
$luma-status-orange: #f7dd7a;
$luma-status-red: #ff7c67;
$luma-sys-slateblue: #4A5660;
$luma-sys-gray: #CFD2D5;
$luma-sys-mainbg: #F5F6F6;
$luma-nav-navblue: #263541;
$luma-nav-selectedblue: #192A36;
$luma-nav-openbg: #313F4A;
$luma-tbl-hd-fg: #56626B;
$luma-tbl-hd-bg: $luma-sys-gray;
$luma-tbl-bg-1: #FFFFFF;
$luma-tbl-bg-2: #F9F9FA;
$luma-hlt-navblue: #C6DA7C;
$luma-hlt-dropdownbg: #4A90E2;

$luma-hlt-dropdownbg-selected:	#EEEFEF;
$luma-hlt-inactivefield: #DFE3E8;
$luma-green: #99AB55;
$alert-red: #D0021B;

$luma-link: #1A75E0;
$luma-dark-blue-gray: #0e1f2cc1;
$luma-light-gray:#cfd2d5;
$luma-even-table-gray: #f5f5f5;
$luma-medium-light-gray: #C4C4C4FF;
$luma-medium-gray: #c4c4c4;
$luma-secondary-button: #ddebfb;
$luma-secondary-button-text: #4a90e2;


$luma-grid-header-gray: #313f4a;

$pure-white: #ffffff;
$pure-black: #000000;

$luma-high-bid: #4472C4;
$luma-middle-bid: #B4C6E7;
$luma-low-bid: #DDEBF7;
$luma-high-uf-dp-bid: #A93E3D;
$luma-middle-uf-dp-bid: #EF8A62;
$luma-low-uf-dp-bid: #FED79C;

$luma-dark-blue-grey: #0e1f2cc1;
$luma-light-grey:#cfd2d5;

$luma-disabled-text: #848484;
$luma-disabled-background: #e0e0e0;

$luma-error-background: #f8d7da;
$luma-error-border: #f5c6cb;
$luma-error-color: #721c24;

$luma-success-color: #155724;

// Gray Tabs
$luma-tab-primary: #4b5761;

$luma-primary-v2: #00213c;
$grid-header-gray: #eff2f4;



// Graphing
$graphing-blue-1: #033956;
$graphing-blue-2: #154560;
$graphing-blue-3: #24526b;
$graphing-blue-4: #325f77;
$graphing-blue-5: #3f6e84;
$graphing-blue-6: #4c7e93;
$graphing-blue-7: #5990a3;
$graphing-blue-8: #67a2b3;
$graphing-blue-9: #75b7c5;
$graphing-blue-10: #86d2dc;



// BEGIN THE ALMIGHTY STYLE GUIDE (*zeplin)

// COLORS
$black: #000000;
$button-hover: #2b577d;
$confirm-green: #46b11d;
$cta-primary-blue: #00263e;
$disabled-grey: #e8e8e8;
$disabled-text: #868686;
$entry-field-grey: #f1f1f1;
$error-red: #af2a2a;
$header-font: #4f4f4f;
$header-font: #ffffff;
$header-row: #eff2f4;
$highlight-green: #cbe880;
$info-blue: #eaf5ff;
$info-grey: #eff2f4;
$input-field-grey: #cdcdcd;
$link-blue: #2a86d1;
$luma-yellow: #fed109;
$medium-grey: #717171;
$text-grey: #333538;
$white: #ffffff;
$luma-selective-yellow: #f5b901;
$active-green: #e5f2b5;

$secondary-gray: #EEEEEE;

// NEW NEW COLORS
$darkening-background: rgba(0, 0, 0, 0.75);
$tag-grey: #eff2f4;
$tag-blue: #b6d6e2;
$hover-grey: #dadada;
$luma-orange: #ff961c;
$selected-grey: #bbb;
$luma-jet: #292929;
$disabled-text-grey: #868686;
$luma-yellow-green: #c9de7d;
$tag-green: #d7e79d;
$cta-tabs-fill-active: #cbe880;
$product-information-product-section-background-hover: #d2d2d2;
$platform-navigation-regular-user-admin-deselected: #033956;
$product-information-product-section-background-active: #e5e5e5;
$luma-half-baked: #93C3D5;
$luma-daintree: #00263e;
$graphing-blue-gray-1: #435560;
$graphing-blue-gray-2: #546a78;
$graphing-blue-gray-3: #647f90;
$graphing-blue-gray-4: #7a92a2;
$graphing-blue-gray-5: #93a7b4;
$graphing-luma-selective-yellow-1: #876708;
$graphing-luma-selective-yellow-2: #ae850a;
$graphing-luma-selective-yellow-3: #d4a20c;
$graphing-luma-selective-yellow-4: #f2bb17;
$graphing-luma-selective-yellow-5: #f4c63e;
$graphing-luma-daintree-1: #113d55;
$graphing-luma-daintree-2: #185677;
$graphing-luma-daintree-3: #1e6e99;
$graphing-luma-daintree-4: #2585b9;
$graphing-luma-daintree-5: #339dd7;
$graphing-luma-half-baked-1: #2f6274;
$graphing-luma-half-baked-2: #3b7a91;
$graphing-luma-half-baked-3: #4792ae;
$graphing-luma-half-baked-4: #5fa5be;
$graphing-luma-half-baked-5: #7db5ca;
$graphing-luma-yellow-green-1: #434f17;
$graphing-luma-yellow-green-2: #5d6e21;
$graphing-luma-yellow-green-3: #788d2a;
$graphing-luma-yellow-green-4: #93ae34;
$graphing-luma-yellow-green-5: #aac743;
$grid-blue-1: #7f929e;
$grid-blue-2: #d9dfe2;
$grid-blue-3: #bfc9cf;
$grid-blue-4: #abb4b9;

// SPACING
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 40px;

// FONTS
$font-family: Montserrat;

// breakpoints scss

@mixin responsive-font-size($fs-map) {
  @each $breakpoint, $font-size in $fs-map {  
    @media screen and (min-width: $breakpoint) {
      font-size: $font-size;
    }
  }
}


$space-between-cards: 1em;
$grid-label-font-size: .855em;
$grid-value-font-size: .855em;

$responsive-font-sizes: (
  0px  : 14px,
  2024px: 16px,
  2560px: 20px
);

// This will be moved to it's own file with other templates
%no-select {
  -ms-user-select: none; /* IE 10 and IE 11 */
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
}

// NEW NEW COLORS
$darkening-background: rgba(0, 0, 0, 0.75);
$tag-grey: #eff2f4;
$tag-blue: #b6d6e2;
$hover-grey: #dadada;
$luma-orange: #ff961c;
$selected-grey: #bbb;
$luma-jet: #292929;
$disabled-text-grey: #868686;
$luma-yellow-green: #c9de7d;
$tag-green: #d7e79d;
$cta-tabs-fill-active: #cbe880;
$product-information-product-section-background-hover: #d2d2d2;
$platform-navigation-regular-user-admin-deselected: #033956;
$product-information-product-section-background-active: #e5e5e5;
$luma-half-baked: #93C3D5;
$luma-daintree: #00263e;
$graphing-blue-gray-1: #435560;
$graphing-blue-gray-2: #546a78;
$graphing-blue-gray-3: #647f90;
$graphing-blue-gray-4: #7a92a2;
$graphing-blue-gray-5: #93a7b4;
$graphing-luma-selective-yellow-1: #876708;
$graphing-luma-selective-yellow-2: #ae850a;
$graphing-luma-selective-yellow-3: #d4a20c;
$graphing-luma-selective-yellow-4: #f2bb17;
$graphing-luma-selective-yellow-5: #f4c63e;
$graphing-luma-daintree-1: #113d55;
$graphing-luma-daintree-2: #185677;
$graphing-luma-daintree-3: #1e6e99;
$graphing-luma-daintree-4: #2585b9;
$graphing-luma-daintree-5: #339dd7;
$graphing-luma-half-baked-1: #2f6274;
$graphing-luma-half-baked-2: #3b7a91;
$graphing-luma-half-baked-3: #4792ae;
$graphing-luma-half-baked-4: #5fa5be;
$graphing-luma-half-baked-5: #7db5ca;
$graphing-luma-yellow-green-1: #434f17;
$graphing-luma-yellow-green-2: #5d6e21;
$graphing-luma-yellow-green-3: #788d2a;
$graphing-luma-yellow-green-4: #93ae34;
$graphing-luma-yellow-green-5: #aac743;
$grid-blue-1: #7f929e;
$grid-blue-2: #d9dfe2;
$grid-blue-3: #bfc9cf;
$grid-blue-4: #abb4b9;

// Colors from morningstar
$porcelain: #eff2f4;
$daintree: #00263e;
$celeste: #cccccc;
$geyser: rgba(217, 223, 226, 1);

//responsiveness breakpoints from morningstar
$wrap-labels-under: 1590px;
$shrink-labels: 1590px;
$labels-under-2-rows: 839px;
$labels-under-3-rows: 467px;


// Overrides
// Tabs
$override-tab-highlight-color: var(--tab-bar-color, $highlight-green);
$override-tab-hover-color: var(--tab-color-hover, $button-hover);

// Button Group
$override-button-group-active-bg: var(--button-background, $luma-brand-bright-green);
$override-button-group-active-text: var(--button-text-color, $text-grey);
$override-button-group-hover-bg: var(--button-group-hover, $luma-brand-hover-bright-green);
$override-button-group-hover-text: var(--button-text-color, inherit);
$override-button-group-inactive-bg: var(--button-inactive-background, $white);
$override-button-group-disabled-bg: var(--button-background, rgba($luma-brand-bright-green, 0.5));


// Filters
$override-filter-active-background: var(--filter-active-background, $active-green);
$override-filter-active-text: var(--filter-active-text, $luma-daintree);

// Sort icons
$override-sort-icon-selected-bg: var(--sort-icon-selected-bg, $tag-green);
$override-sort-icon-selected-text: var(--sort-icon-selected-text, $luma-daintree);

// Panels
$override-panels-tab-active-color: var(--panel-active-color, $luma-brand-bright-green);
$override-panels-tab-inactive-color: var(--panel-active-color, $disabled-grey);

// ACCENT COLORS
$ACCENT_10: #faffe3;
$ACCENT_20: #eff9c3;
$ACCENT_30: #e1f0a2;
$ACCENT_40: #cee180;
$ACCENT_50: #aac743;
$ACCENT_60: #95ab3c;
$ACCENT_70: #708420;
$ACCENT_80: #4a5a0c;
$ACCENT_90: #273201;

// NEGATIVE COLORS
$NEGATIVE_10: #f4d4d5;
$NEGATIVE_20: #efacad;
$NEGATIVE_30: #e68485;
$NEGATIVE_40: #d95d5e;
$NEGATIVE_50: #b62a27;
$NEGATIVE_60: #961b1e;
$NEGATIVE_70: #831518;
$NEGATIVE_80: #5c0b0b;
$NEGATIVE_90: #341011;

// NEAUTRAL COLORS
$NEUTRAL_00_WHITE: #ffffff;
$NEUTRAL_10: #f2f4f5;
$NEUTRAL_20: #e7ecee;
$NEUTRAL_30: #d8dfe2;
$NEUTRAL_40: #c4cdd1;
$NEUTRAL_50: #a9b3b8;
$NEUTRAL_60: #858f93;
$NEUTRAL_70: #5a6368;
$NEUTRAL_75: #454b4e;
$NEUTRAL_80: #2f3336;
$NEUTRAL_85: #1a1d1f;
$NEUTRAL_90: #070708;

// POSITIVE COLORS
$POSITIVE_10: #eef4db;
$POSITIVE_20: #d5e6aa;
$POSITIVE_30: #bbd97e;
$POSITIVE_40: #9fcd5a;
$POSITIVE_50: #82c241;
$POSITIVE_60: #65a343;
$POSITIVE_70: #487939;
$POSITIVE_80: #314c22;
$POSITIVE_90: #192313;

// PRIMARY COLORS
$PRIMARY_10: #e0f4ff;
$PRIMARY_20: #a0dcf8;
$PRIMARY_30: #61c0ed;
$PRIMARY_40: #26a0dc;
$PRIMARY_50: #007dc2;
$PRIMARY_60: #02629d;
$PRIMARY_70: #004570;
$PRIMARY_80_DAINTREE: #00263e;
$PRIMARY_90: #00090f;

// WARNINGS
$WARNING_10: #fbf7cc;
$WARNING_20: #f7e78a;
$WARNING_30: #efdb51;
$WARNING_40: #dfcb22;
$WARNING_50: #c9b82d;
$WARNING_60: #a99e34;
$WARNING_70: #847c30;
$WARNING_80: #5a5624;
$WARNING_90: #333115;

$variant-primary-colors: (
        top-nav-background: $PRIMARY_80_DAINTREE,
        top-nav-border: $PRIMARY_80_DAINTREE,
        account-icon-main-color: $NEUTRAL_00_WHITE,
        dropdown-icon: $PRIMARY_80_DAINTREE,
        account-icon-active-color: $PRIMARY_50,
        text-color: $PRIMARY_80_DAINTREE,
        active-highlight-color: $PRIMARY_50,
        tab-hover-color: $PRIMARY_60,
);

$variant-secondary-colors: (
        top-nav-background:$NEUTRAL_00_WHITE,
        top-nav-border:$NEUTRAL_40,
        account-icon-main-color:$NEUTRAL_80,
        dropdown-icon:$NEUTRAL_80,
        account-icon-active-color:$NEUTRAL_50,
        text-color:$NEUTRAL_80,
        active-highlight-color:$NEUTRAL_50,
        tab-hover-color:$NEUTRAL_60,
);

@mixin generate-css-variables($map, $prefix) {
  @each $key, $value in $map {
    --#{$prefix}-#{$key}: #{$value};
  }
}

:root {
  @include generate-css-variables($variant-primary-colors, 'primary');
  @include generate-css-variables($variant-secondary-colors, 'secondary');
}
