@import 'variables';

@mixin chipProps() {
  color: $medium-grey;
  padding: .25em .75em;
  border-radius: 4px;
  text-align: center;
  line-height: 2em;
  min-width: max-content;
  width: max-content;
  display: inline-block;
  vertical-align: middle;
}

.grey-chip {
  @include chipProps;
  background: $info-grey;
  border: solid 1px $info-grey;
  &-sm {
    @extend .grey-chip;
    padding: 0 .5em;
  }
}
.outlined-chip {
  @include chipProps;
  border: solid 1px $input-field-grey;
  &-sm {
    @extend .outlined-chip;
    padding: 0 .5em;
  }
}

.filled-chip {
  @include chipProps;
  background: $info-blue;
  border: solid 1px $info-blue;
  &-sm {
    @extend .filled-chip;
    padding: 0 .5em;
  }
}
