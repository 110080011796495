@import 'variables';

// Mixins

@mixin large-button($parent) {
  @at-root #{$parent}-lg {
    @extend #{$parent};
    @extend .luma-btn-lg;
  }
}

// CSS

.luma-btn {
  border-radius: 4px;
  border-width: 0;
  font-size: 0.875em;
  letter-spacing: normal;
  line-height: 2.25em;
  min-width: 4em;
  padding: 0 1em;
  text-align: center;

  &:disabled, &[disabled] {
    opacity: 0.7;
  }

  &:hover:enabled, &:hover[enabled] {
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.05));
  }

  &-lg {
    font-size: 1.125rem;
    line-height: 2.5rem;
    padding: 0 1.35rem;
  }

  &-fit {
    width: fit-content;
  }
}

.disabled-btn {
  &:disabled, &[disabled] {
    color: $disabled-text;
    opacity: 0.5;
    pointer-events: none;
  }
}

.clicked-btn {
  cursor: pointer;
  &:focus-visible, &[focus-visible] {
    box-shadow: none;
    outline: 0;
  }

  &:active, &[active] {
    filter: saturate(150%);
    transform: translateY(1px);
  }
}

.primary-btn-v2 {
  @extend .disabled-btn; // Keep first to overwrite luma-btn disable selector
  @extend .luma-btn;
  @extend .clicked-btn;
  @include large-button(&);
  background: $luma-primary-v2;
  border-radius: 4px;
  color: $white;
  font-family: 'Montserrat';
  &:hover:not(:disabled), &:hover:not([disabled]),
  &:hover:enabled, &:hover[enabled], &:focus-visible, &[focus-visible] {
    background-color: $button-hover;
    background-image: none;
  }

  &:disabled {
    background-color: $disabled-grey;
    color: $disabled-text;
    outline: 1px solid $input-field-grey;
  }
}

.secondary-btn-v2 {
  @extend .disabled-btn; // Keep first to overwrite luma-btn disable selector
  @extend .luma-btn;
  @extend .clicked-btn;
  @include large-button(&);
  background: $white;
  border-radius: 4px;
  color: $link-blue;
  font-family: 'Montserrat';
  height: 3em;
  outline: solid 1px $input-field-grey;
  &:hover:enabled, &:hover[enabled], &:focus-visible, &[focus-visible] {
    background-color: $info-blue;
    background-image: none;
  }

  &:disabled {
    color: $disabled-text;
  }

}

.secondary-btn-v2-1 {
  @extend .disabled-btn; // Keep first to overwrite luma-btn disable selector
  @extend .luma-btn;
  @include large-button(&);
  min-width: max-content;
  cursor: pointer;
  background: $white;
  color: $link-blue;
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: $spacing-xs;
  height: 2.5em;
  font-weight: 500;
  border-radius: 8px;
  font-family: 'Montserrat';
  outline: solid 1px $input-field-grey;
  &:hover:enabled, &:hover[enabled], &:focus-visible, &[focus-visible] {
    background-color: $info-blue;
    background-image: none;
    outline: solid 1px $graphing-luma-daintree-4;
  }

  &:disabled {
    color: $disabled-text;
  }
}

.primary-btn {
  @extend .disabled-btn; // Keep first to overwrite luma-btn disable selector
  @extend .luma-btn;
  @extend .clicked-btn;
  @include large-button(&);
  background: $luma-link;
  color: $pure-white;
}

.tertiary-btn {
  @extend .disabled-btn; // Keep first to overwrite luma-btn disable selector
  @extend .luma-btn;
  @extend .clicked-btn;
  @include large-button(&);
  background: $luma-brand-green;
  color: rgba(0, 0, 0, 0.6);
}

.cancel-btn {
  @extend .luma-btn;
  @extend .clicked-btn;
  @include large-button(&);
  background: $luma-sys-gray;
  color: rgba(0, 0, 0, 0.6);
}

.secondary-btn {
  @extend .luma-btn;
  @extend .clicked-btn;
  background: $luma-secondary-button;
  color: $luma-secondary-button-text;

  &:active, &[active] {
    outline: solid 1px $luma-secondary-button-text;
  }
}

.no-background-btn {
  @extend .disabled-btn;
  background: none;
  border: none;
}

.text-cta {
  @extend .no-background-btn;
  @extend .clicked-btn;
  color: $link-blue;
  font-family: 'Montserrat';
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

/**
 * @deprecated Don't use this, use text-cta
*/
.text-btn {
  @extend .no-background-btn;
  @extend .clicked-btn;
  color: $link-blue;
  font-size: 1em;
  font-weight: bold;
}

.text-btn-sm {
  @extend .text-btn;
  font-size: 0.75em;
}

.text-btn-lg {
  @extend .text-btn;
  font-size: 1.25em;
}

.cursor-pointer,
.cursor-pointer:hover {
  cursor: pointer;
}

.multi-chip {
  @extend .luma-btn;
  background-color: white;
  border-radius: 4px;
  font-family: 'Montserrat';
  outline: 1px solid $input-field-grey;
  padding: 0 2em;

  &-active {
    background-color: $highlight-green
  }
  &-disabled {
    @extend .multi-chip;
    background-color: $disabled-grey;
    pointer-events: none;
    &:active, &[active] {
      filter: saturate(100%);
      transform: translateY(0);
    }
  }
}

.delete-trash-can {
  color: $error-red;
  column-gap: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.pill-container {
  align-items: center;
  background-color: white;
  border: solid 1px transparent;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 32px;
  padding: $spacing-xs $spacing-s;

  &:focus, &:hover, &:active {
    border: solid 1px $graphing-luma-daintree-4;
  }
}
