@import 'variables';

$input-font-sizes: (
        0px: 12px,
        300px : 14px,
);

.luma-input {
  & :-webkit-autofill::first-line {
    font-family: 'Montserrat' !important;
  }

  &.mat-form-field {
    @include responsive-font-size($input-font-sizes);
    font-family: 'Montserrat';
    & .mat-select {
      font-family: inherit;
    }

    &.mat-focused {
      & .mat-form-field-label {
        color: $text-grey;
      }

      &.mat-form-field-appearance-outline {

        & .mat-form-field-outline-gap, & .mat-form-field-outline-start, & .mat-form-field-outline-end {
          border-color: $text-grey;
        }

        & .mat-form-field-outline-gap {
          border-top-color: transparent;
        }
      }
    }
  }

  .mat-form-field-flex > .mat-form-field-infix {
    border-top-width: .6em;
    padding: 0.5em 0px .9em 0px;
  }

  .mat-form-field-label-wrapper {
    top: -1.4em;
  }


  &.mat-form-field-appearance-outline {
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.4em) scale(.75);
      width: 133.33333%;
    }

    & .mat-form-field-outline-start {
      border-radius: 4px 0 0 4px;
    }

    & .mat-form-field-outline-end {
      border-radius: 0 4px 4px 0;
    }

    &.mat-focused {
      color: $text-grey;
    }

    // This is needed for mat-selects to align the arrow in the middle because of our custom shorter height
    .mat-select-arrow-wrapper {
      transform: none;
    }
  }

  .mat-form-field-label-wrapper {
    top: -1em;
  }

  & button {
    top: 2px;
  }

  &.extended-error-messages {
    .mat-form-field-subscript-wrapper {
      margin-top: 4px;
      padding: 0 0 0 2px;
      width: 150%;
    }
  }

  // TODO - make responsiveness
  &-small-text {
    @extend .luma-input;

    .mat-form-field-wrapper {
      padding-bottom: calc(1.71em - 0.25em);
    }

    &.mat-form-field-appearance-outline .mat-form-field-prefix,
    &.mat-form-field-appearance-outline .mat-form-field-suffix {
      top: auto;

      & .mat-datepicker-toggle {
        vertical-align: sub;
      }
    }

    input.mat-input-element,
    .mat-form-field-label mat-label,
    .mat-select-value-text > span.mat-select-min-line,
    .mat-select-value > span.mat-select-placeholder {
      font-size: 12px;
    }
  }

  &.disabled-input {
    pointer-events: none;
    @extend %no-select;
  }
}

.luma-input-white {
  @extend .luma-input;
  .mat-form-field-outline {
    background-color: white;
  }
}

.luma-checkbox {
  &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $link-blue;
  }

  & .mat-checkbox-checkmark-path {
    stroke-width: 4px;
  }

  & .mat-checkbox-label {
    font-family: 'Montserrat';
  }
}

// TODO - Zach Fowles
.white-input-background {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
  }
}

.flex-width-form-fields {
  .mat-form-field-infix {
    width: auto;
  }
}

.grid-search-box {
  font-size: .855em;
  width: 20em;
  & .mat-form-field-outline {
    background-color: white !important;
  }
}

.show-required {
  &.ng-invalid, .mat-focused {
    .mat-form-field-outline {
      color: orange;
    }
  }
}

// Non-material css styling
.design-system-input {
  font-size: .855em;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  font-family: $font-family;
  padding: 0 2.3em 0 .7em;
  flex: 1;
  &::placeholder {
    color: $grid-blue-1;
    font-family: $font-family;
    font-weight: 500;
  }
}

.design-system-input-container { // use if you want to include a fa-icon in your input
  position: relative;
  height: 2.1em;
  display: flex;
  & > .design-system-input {
    height: 100%;
  }
  fa-icon {
    font-size: 0.855em;
    margin-right: $spacing-xs;
    margin-top: $spacing-xs;
    position: absolute;
    right: 0;
  }
}
