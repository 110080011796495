.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

//// Layout /////

.ml-0 { margin-left: 0px; }
.ml-4 { margin-left: 4px; }
.ml-8 { margin-left: 8px; }
.ml-12 { margin-left: 12px; }
.ml-16 { margin-left: 16px; }
.ml-20 { margin-left: 20px; }
.ml-24 { margin-left: 24px; }

.mr-0 { margin-right: 0px; }
.mr-4 { margin-right: 4px; }
.mr-8 { margin-right: 8px; }
.mr-12 { margin-right: 12px; }
.mr-16 { margin-right: 16px; }
.mr-20 { margin-right: 20px; }
.mr-24 { margin-right: 24px; }

.mt-0 { margin-top: 0px; }
.mt-4 { margin-top: 4px; }
.mt-8 { margin-top: 8px; }
.mt-12 { margin-top: 12px; }
.mt-16 { margin-top: 16px; }
.mt-20 { margin-top: 20px; }
.mt-24 { margin-top: 24px; }

.mb-0 { margin-bottom: 0px; }
.mb-4 { margin-bottom: 4px; }
.mb-8 { margin-bottom: 8px; }
.mb-12 { margin-bottom: 12px; }
.mb-15 { margin-bottom: 15px; }
.mb-16 { margin-bottom: 16px; }
.mb-20 { margin-bottom: 20px; }
.mb-24 { margin-bottom: 24px; }

.ml-3p { margin-left: 3%; }
.ml-5p { margin-left: 5%; }
.ml-7p { margin-left: 7%; }
.ml-8p { margin-left: 8%; }
.ml-10p { margin-left: 10%; }

.m-0 { margin: 0; }

.l-w100p { width: 100%; }
.l-w80p { width: 80%; }
.l-w130 { width: 130px; }
.l-w160 { width: 160px; }
.l-w193 { width: 193px; }
.l-w200 { width: 200px; }
.l-w202 { width: 202px; }
.l-w215 { width: 215px; }
.l-w250 { width: 250px; }
.l-w320 { width: 320px; }
.l-w400 { width: 400px; }
.l-w434 { width: 434px; }
.l-w500 { width: 500px; }
.l-w840 { width: 840px; }
.l-w960 { width: 960px; }
.l-h75vh { height: 75vh; }
.l-h90vh { height: 90vh; }

@for $i from 1 through 30 {
  $class-name: '.min-wid-#{$i}ch';
  #{$class-name} {
    min-width: #{$i}ch;

    &-inline {
      @extend #{$class-name};
      display: inline-block;
    }

  }
}

.no-word-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pre-line {
  white-space: pre-line;
}
