// AG - GRID CSS AND CUSTOMIZATION
@import 'variables';

//$icons-path: "~@ag-grid-community/src/styles/ag-theme-base/icons";      This does not work!
$icons-path: "node_modules/ag-grid-community/src/styles/ag-theme-base/icons/";

//icon-color : #c6da7c;  not working either ! https://github.com/ag-grid/ag-grid/issues/2151
.ag-theme-material .ag-header-icon {
  // Easy way to achieve color way for modern browser, can also change the actual svg or use a webpack plugin
  // https://codepen.io/sosuke/details/Pjoqqp
  // filter: invert(85%) sepia(29%) saturate(539%) hue-rotate(24deg) brightness(93%) contrast(95%);
  color: $luma-brand-green !important;
  
}

@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

.ag-theme-material {
  @include ag-theme-material((
    header-background-color : #313f4a,
    header-foreground-color : white,
    checkbox-background-color: white,
    checkbox-checked-color: #0091EA,
    checkbox-unchecked-color: #0091EA,
    checkbox-indeterminate-color: #0091EA,
    odd-row-background-color: #f5f5f5,
  ));

  // TODO - DO NOT MERGE
  &.test .ag-header-cell {
    border-right: 1px solid white;
  }
}

.ag-theme-material .ag-floating-filter-input .ag-input-field-input {
  background-color: white !important;
  font-size: 16px !important;
  height: 25px !important;
  padding-top: 12px !important;
}


.ag-cell .underlying {
  line-height: 16px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #444444
}

.ag-cell .underlying:last-child {
  padding-bottom: 8px;
}

.ag-theme-material .gridRowShaded {
  background: #00000003;
}

.ag-theme-material .ag-row-selected {
  background: rgba(207, 225, 135, 0.3);
}

// TO-DO commented because evaluating the design change
// .ag-container .ag-theme-material .ag-cell {
//   border-right: 1px solid rgba(226, 226, 226, .5);
// }

.ag-theme-material .ag-cell-data-changed {
  background-color: $luma-alert-green!important;
}

.ag-container .ag-theme-material .ag-selection-checkbox ~ .ag-cell-value:not(:empty) {
  margin-left: 16px;
}

.ag-container{
  // Sticky header
  & .ag-header  {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  & .ag-item, .ag-root-wrapper, .ag-root   {
    overflow: visible!important;
  }

  & .ag-theme-material .ag-cell, & .ag-header-cell {
    padding-right: 8px;
    padding-left: 8px;
  }

  & .loadingOverlay {
    margin-top: 60px;
    color: $luma-dark-blue-grey;
  }

  & .noRowsOverlay {
    margin-top: 30px;
  }

  & .issuerName {
    position: absolute;
    left: 45px;
    color: $luma-dark-blue-grey
  }

}

.ag-container .ag-pinned-left-header .ag-header-cell:nth-child(1) {
  display: flex;
  justify-content: center;

  .ag-header-select-all {
    margin-right: 0;
    margin-left: 6px;
  }
}

.ag-container .ag-theme-material.ag-auction .issuerIcon {
  margin-top: 0;
}

.ag-container .ag-theme-material.ag-auction .issuerName {
  position: initial;
  // font: 500 14px/14px 'Roboto', 'Helvetica Neue', sans-serif;
}

.ag-container .ag-theme-material.ag-auction  .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
  height: 100%;
}

ag-grid-issuer-cell {
  height: 100%;
  width: 100%;
}

app-ag-grid-counterparty-editor-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-auction .ag-body-viewport {
  max-height: calc(100vh - 245px);
  min-height: 500px;
  overflow-y: scroll;
}

.ag-cdt .ag-body-viewport {
  max-height: calc(100vh - 218px);
  min-height: 500px;
  overflow-y: scroll;
}

.ag-theme-material .ag-checkbox-input-wrapper {
    font-size: 20px;
    width: 19px;
    height: 20px;
    line-height: 20px;
}

.product-details-grid.ag-theme-material .ag-cell {
  line-height: 27px;
}

.product-details-grid.ag-theme-material .ag-group-value {
  height: 46px;
  line-height: 46px;
}

.align-cell-center {
  & .ag-cell-wrapper {
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
}

.align-header-center {
  & .ag-header-cell-label {
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
}

.align-cell-start  {
  & .ag-cell-wrapper {
    justify-content: start;
    text-align: left;
  }
}

.align-header-start {
  & .ag-header-cell-label {
    justify-content: start;
    text-align: left;
  }
}

.center-ag-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  &-btn {
    @extend .center-ag-cell;
    & .ag-cell-wrapper {
      justify-content: center;
      width: 100%;
      & .ag-cell-value {
        width: 100%;
        padding: 0 0.5em;
        & button {
          width: 100%;
        }
      }
    }
  }
}

.cell-no-padding,
.ag-theme-material .ag-cell.cell-no-padding {
  padding: 0;
}

app-lifecycle-manager-overview {
  .info-circle-container {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: baseline;
    padding-top: 5px;

    .info-circle {
      height: 20px;
    }
  }

  .high-chart-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ag-theme-material{
  .ag-cell.editing-buttons {
    padding: 0;
  }
}

app-underlier-table {
  app-holiday-date {
    height: 100%;
  }

  .first-trade-date {
    display: flex;

    .mat-form-field {
      display: flex;
      padding-top: 4px;
    }

    .mat-form-field-flex {
      align-items: center;
      padding-top: 0;
    }
    
    .mat-form-field-infix {
      border-top: 0;
      padding: 0;
      height: 100%;
      display: flex;

      input {
        align-self: center;
      }
    }
    .mat-form-field-underline {
      .mat-form-field-ripple {
        background-color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

app-holiday-maintainence {
  app-holiday-date {
    height: 100%;
  }

  .holiday-date {
    display: flex;

    .mat-form-field {
      display: flex;
      padding-top: 4px;
    }

    .mat-form-field-flex {
      align-items: center;
      padding-top: 0;
    }
    
    .mat-form-field-infix {
      border-top: 0;
      padding: 0;
      height: 100%;
      display: flex;

      input {
        align-self: center;
      }
    }
    .mat-form-field-underline {
      .mat-form-field-ripple {
        background-color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

app-notification-center-inbox  {
  .attachments {
    fa-icon {
      width: 10px;
      height: 15px;
    }
  }

  .status-text {
    padding-right: 1rem;
  }

  .fail-status-text {
    padding-right: 1rem;
    color: red;
  }

  .fail-close {
    color: red;
  }

  .resend-button {
    mat-progress-spinner {
      align-self: center;
      margin-right: 1rem;
    }
  }


  .status-text {
    padding-right: 1rem;
  }

  .resend-button {
    mat-progress-spinner {
      align-self: center;
      margin-right: 1rem;
    }
  }

  .text-center {
    .ag-header-cell-label {
      justify-content: center;
      padding-left: 15px;
    }
  }
}

.ag-theme-material .ag-row {
  &.warning-grid-row {
    background-color: pink;
  }
}



.no-scrollbars .ag-tool-panel-wrapper, .no-scrollbars .ag-body-viewport {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.ag-theme-material.base-structured-products-grid {
  @include ag-theme-material((
          selected-row-background-color: white,
          row-hover-color: white,
          header-foreground-color : black,
          checkbox-background-color: white,
          checkbox-checked-color: #0091EA,
          control-panel-background-color: white,
          checkbox-unchecked-color: #0091EA,
          checkbox-indeterminate-color: #0091EA,
          header-column-separator: true,
          header-background-color: $grid-header-gray,
          font-size: 1em,
          font-family: $font-family
  ));

  .select-col {
    display: block;

    .ag-cell-wrapper, .ag-selection-checkbox, .ag-cell-value {
      display: block;
    }

  }

  & .ag-checkbox-input-wrapper input {
    padding: 2em 4em;
  }

  & .ag-header {
    height: 3em;
  }

  & .ag-header-cell {
    font-size: inherit;
  }

  & .ag-cell-wrapper {
    align-items: start;
  }


  & .ag-header-icon {
    color: #313f4a !important;
  }

  & .ag-header-cell {
    @include responsive-font-size($responsive-font-sizes);
    font-weight: 500;
    & .ag-header-cell-text {
      font-size: .855em;

    }
  }



  .ag-side-bar {
    border-right: 1px solid $pure-black;

    & .ag-filter-toolpanel-header {
      height: 3.5em;
    }
    & .ag-side-buttons {
      background-color: $grid-header-gray;
      color: $luma-primary-v2;
    }

    & .ag-column-select-column-drag-handle {
      display: none;
    }

    & .ag-filter-toolpanel {
      & .mat-form-field-outline {
        background-color: $pure-white;
        height: 2.8em;

        & .mat-form-field-infix {
          padding-top: .5em !important;
        }
      }
      & .mat-form-field-appearance-outline {
        & .mat-form-field-infix {
          padding-top: .5em;
        }
      }

      & button.mat-focus-indicator.mat-icon-button.mat-button-base {
        top: .375em;
      }

    }
    & .ag-tool-panel-wrapper {
      min-width: 19em;
      width: min-content;
    }
    & .ag-mini-filter {
      margin-top: -.5em;
      background-color: white;
      border: 1px solid;
      &:focus-within {
        border: 1px solid $luma-link;
      }
      & .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
        margin-left: .75em;
        margin-bottom: -.4em;

        input {
          border-bottom: none !important;
        }
      }
    }

    & .ag-filter-toolpanel-group-wrapper:not(:first-child) {
      border-top: 1px solid $luma-disabled-background;
    }

    & .ag-group-container.ag-group-container-vertical.ag-filter-toolpanel-group-container {
      background: linear-gradient(180deg, #eff2f4 43%, #fbfbfc 100%);
    }

    & .ag-group-title-bar.ag-filter-toolpanel-group-title-bar {
      font-size: .9em;
      font-weight:normal;
      background-color: $grid-header-gray;
    }
    & .ag-side-buttons {
      width: 2.5em;
      span.ag-side-button-label {
        margin-left: .6em;
        font-size: 1em;
        font-weight: 700;
      }
    }
  }

  & .ag-pinned-right-header {
    margin-right: -1.375em;
    // margin-right: -22px;
  }

  & .no-border {
    border:none !important;
    outline: none;
  }
}


.ag-theme-material.annuities-grid {
  @extend .base-structured-products-grid;
  .ag-row {
    border-bottom: $space-between-cards solid #f4f6f8;
  }
}

.ag-theme-material.annuities-orders-grid {
  @extend .base-structured-products-grid;

  .padded-height {
    margin-bottom: 0.5em;

    .field-container {
      &:last-of-type {
        margin-bottom: 8px;
      }
      &:first-of-type {
        margin-top: 8px;
      }
    }

    .field-value:last-of-type {
      padding-bottom: 8px;
    }
  }

  .ag-cell-value {
    width: 100%;
    height: 100%;
  }
  // todo: if we want checkboxes in the headers keep, otherwise remove
  //.ag-checkbox-input-wrapper.ag-checked::after {
  //  color: $luma-primary-v2 !important;
  //}
  .ag-row {
    border-bottom: 0.5em solid #f4f6f8;
  }
  .visible-overflow * .ag-full-width-row,
  .visible-overflow.ag-full-width-row {
    overflow: visible;
  }

  & .no-border-bottom {
    .ag-row {
      border-bottom: 0;
      height: 32px;
    }
    .ag-cell {
      line-height: 32px;
    }
  }
}

.ag-theme-material.structured-products-grid {
  @extend .base-structured-products-grid;
  .ag-cell-value {
    width: 100%;
    height: 100%;
  }
  // todo: if we want checkboxes in the headers keep, otherwise remove
  //.ag-checkbox-input-wrapper.ag-checked::after {
  //  color: $luma-primary-v2 !important;
  //}
  .ag-row {
    border-bottom: $space-between-cards solid #f4f6f8;
  }
}

.ag-theme-material.company-administration-grid {
  @extend .base-structured-products-grid;
  & .ag-cell-wrapper {
    margin-top: 0;
  }
}

.column-header-no-pointer {
  pointer-events: none;
}

.broker-dealer-fixed-closing-time-suffix .suffix {
  color: $disabled-text;
}

.broker-dealer-preferences-shown {
  @extend .broker-dealer-center-items;

  .broker-dealer-preferences-status {
    background-color: $luma-selective-yellow;
  }
}

.broker-dealer-preferences-hidden {
  @extend .broker-dealer-center-items;
  .broker-dealer-preferences-status {
    background-color: $confirm-green;
  }
}

.broker-dealer-preferences-status {
  @extend .broker-dealer-center-items;
  width: 5rem;
  height: 2rem;
}

.broker-dealer-center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.annuities-grid .field-container:first-of-type {
  margin-top: $spacing-m;
}

.annuities-grid  .field-container:last-of-type {
  margin-bottom: $spacing-m;
}

.padded-height {
  margin-bottom: calc(1em + #{$space-between-cards});
}
