@import 'variables';

//// ELEMENTS ////
html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0;
}

mat-card-content {
  height: 25em;
  position: relative;
}

mat-card-footer {
  margin-bottom: 10px;
}

mat-card-title {
  padding-left: 16px;
  position: relative;
}


//// Layout /////

.ml-0 {
  margin-left: 0px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-3p {
  margin-left: 3%;
}

.ml-5p {
  margin-left: 5%;
}

.ml-7p {
  margin-left: 7%;
}

.ml-8p {
  margin-left: 8%;
}

.ml-10p {
  margin-left: 10%;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-10p {
  margin-right: 10%;
}

.mt-0 {
  margin-top: 0px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.l-w100p {
  width: 100%;
}

.l-w80p {
  width: 80%;
}

.l-w130 {
  width: 130px;
}

.l-w160 {
  width: 160px;
}

.l-w193 {
  width: 193px;
}

.l-w200 {
  width: 200px;
}

.l-w202 {
  width: 202px;
}

.l-w215 {
  width: 215px;
}

.l-w250 {
  width: 250px;
}

.l-w320 {
  width: 320px;
}

.l-w400 {
  width: 400px;
}

.l-w460 {
  width: 460px;
}

.l-w434 {
  width: 434px;
}

.l-w500 {
  width: 500px;
}

.l-w840 {
  width: 840px;
}

.l-w960 {
  width: 960px;
}

.l-h75vh {
  height: 75vh;
}

.l-h90vh {
  height: 90vh;
}

.mat-raised-button.mb-16 {
  margin-bottom: 16px;
}

.mat-raised-button.mb-8 {
  margin-bottom: 8px;
}

.mat-raised-button.secondary-button {
  background-color: $luma-secondary-button;
  color: $luma-secondary-button-text;
}

//// CLASSES ////

.account-file-upload_header {
  font-size: 24px;
  font-weight: normal;
}

.account-file-upload_header span {
  font-weight: bold;
}

.account-file-upload_select-file-text {
  font-size: large;
}

.all-filter {
  width: 100%;
}

.ag-grid-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 1.0rem;
}

.ag-grid-view-controller {
  margin-right: 6px;
}

.ag-grid-quick-filter-controller {
  float: left;
  margin: 2rem;
}

.ag-grid-quick-filter-controller__user-input-field {
  line-height: 1.8rem;
}

.ag-grid-component-title-container.mat-card-title {
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ag-grid-component-subtitle-container.mat-card-subtitle {
  margin-top: 25px !important;
  margin-right: 20px;
}

.calendar-component-title-container.mat-card-title {
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-details-order-details-title {
  margin-bottom: 0 !important;
}

.product-details-order-button-container {
  margin-right: 7%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ag-grid-component-subtitle-order-container {
  padding-right: 11px;
}

.product-details-order-count {
  margin: 0 !important;
}

.ag-theme-luma-product-details {
  height: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 86%;
}

.approval-status_approved {
  color: green;
}

.approval-status_check {
  color: $luma-green;
  font-size: 24px;
}

.approval-status_warning {
  font-size: 22px;
  color: $luma-brand-orange;
  cursor: pointer;
}

.approval-status-x {
  color: red;
  font-size: 24px;
}

.approval-status_rejected {
  color: red;
}

.approval-status_pending {
  color: $luma-brand-orange;
}

.any-chart-component-title-container.mat-card-title {
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.backdrop {
  height: 5rem;  
}

.breadcrumb {
  display: flex;

}

.breadcrumb-container {
  width: auto;
  height: auto;
  margin-left: 0.6rem;
}

.breadcrumb-icon-container img {
  width: 48px;
}

.breadcrumb-container__breadcrumb-trail {
  margin: 0rem;
}

.breadcrumb-container__breadcrumb-landing {
  margin: 0rem;
}

button.square-button-with-icon>fa-icon>svg:not(:root).svg-inline--fa {
  margin-bottom: 0.35rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.call-proximity-cell-container {
  display: flex;
  justify-content: space-evenly;
}

.call-proximity-cell__event {
  position: relative;
  top: 14px;
}

.call-proximity-cell__strike {
  position: relative;
  top: 14px;
}

.call-proximity-cell__message {
  margin: 0 0.1rem;
}

.call-proximity-cell__message-for-date {
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}

.call-proximity-cell__value {
  margin-left: 0.2rem;
  text-align: right;
}

.call-proximity-cell__icon {
  margin: 0 0.4rem;
  width: 1.0rem;
  text-align: center;
}

.card-content-container {
  padding-top: 1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.calendar-container {
  height: auto;
  overflow: auto;
}

.core-detail-value {
  float: right;
  max-width: 15rem;
  text-align: right;
}

.dashboard {
  margin: 3vh;
}

.disclaimer-brokers-only {
  height: 32px;
  line-height: 1.14;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.chart-container {
  height: 95%;
  overflow: hidden;
  padding: 16px;
}

.chart-type-filters {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
}

.chart-type-filters__chart-type-option:last-child {
  margin-right: 12px;
}

.documentation-tab-content {
  line-height: 1;
  padding-left: 2rem;
  width: 88%;
}

.documentation-panel .mat-dialog-container {
  margin: 0px;
  padding: 0px;
  width: 190px;
  overflow: hidden;
  display: flex;
}

.documentation-dialog-header {
  height: 36px;
  margin: 0px;
  line-height: 1.15;
  letter-spacing: normal;
  padding-top: 11px;
  padding-left: 11.7px;
}

.documentation-dialog-content {
  line-height: 2.31;
  letter-spacing: normal;
  padding-left: 5px;
}

.documentation-column {
  position: absolute;
  top: 16%;
  left: 38%;
}

.excel-icon {
  background-color: white;
  border: none;
  padding: 0px;
  font-size: inherit;
}

.provided-value-cell-container {
  display: flex;
  justify-content: flex-start;
}

.provided-value-cell__message {
  margin: 0;
}

.provided-value-cell__message-for-date {
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}

.provided-value-cell__value {
  margin-left: 0.2rem;
  text-align: right;
  width: 2.0rem;
}

.provided-value-cell__icon {
  margin: 0 0.4rem;
  width: 1.0rem;
  text-align: center;
}

.event-filters__all-container {
  display: flex;
  justify-content: space-between;
}

.example-data-disclaimer {
  display: block;
  line-height: 1.14;
  margin: 0.4rem;
  padding: 0.8rem;
  width: 92%;
}

.example-metrics-app-element {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.example-perfomance-metrics__primary-header {
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin-left: 16px;
  margin-top: 1;
}

.external-filter-group-container {
  display: flex;
}

.external-filter-group {
  flex-grow: 1;
  float: left;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.external-filter-group :first {
  margin-left: 0;
}

.product-data-table-filter {
  margin-bottom: 0.8rem ;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-top: 0.8rem;
}

.external-filter-toggle-option.secondary {
  min-width: 118px;
  height: 44px;
  margin: 0rem;
  margin-left: 0.20rem;
  margin-top: 0.20rem;
}

.fa-icon-with-title {
  margin: 1rem;
  margin-left: 0rem;
}

.file-export-button.mat-flat-button {
  height: 40px;
  margin: 1.0rem;
  margin-top: 0;
}

.file-upload__uploadfilecontainer {

  display: grid;
  grid-template-columns: 1fr 1fr 110px 1fr 1fr;
  grid-template-rows: 7fr 3fr 3fr 1fr 1fr 1fr;
  grid-template-areas:
    ' . bg bg bg . '
    ' .  text text text . '
    ' . file file file . '
    ' . . . . .'
    ' .  . select . . '
    ' . . . . .';
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  // height: 250px;
  height: 425px;
  width: 100%;
  margin: 20px auto;
  border: 2px dashed #1C8ADB;
  border-radius: 10px;
}

.file-upload__bg {
  grid-area: bg;
  margin-right: 36px;
  background-size: 90px;
  background-image: url("https://img.lumafintech.com/assets/upload-luma.png");
  background-repeat: no-repeat;
  background-position: center;
}

.file-upload__files-list {
  grid-area: file;
  display: grid;
  margin: 10px auto;
  width: 80%;
  height: auto;
  overflow: visible;
  background: white;
  border: 1px dashed;
  border-radius: 12px;
  padding: 5px;
  color: #1c8adb;
  grid-template-rows: 4fr 1fr;
  grid-template-columns: 19fr 30px;
  grid-template-areas:
    ' fileName button '
    ' progress-bar progress-bar'
}

.file-upload__file-bar {
  grid-area: fileName;
}

.file-upload__delete-file {
  grid-area: button;
  cursor: pointer;
  font-size: 22px;
  color: $luma-link;
}

.file-upload__progress-bar {
  grid-area: progress-bar;
}

.file-upload__dragAndDropText {
  margin: 10px 0px 0px 0px;
  grid-area: text;
  text-align: center;

}

.file-upload__dragAndDropTextDrag {
  color: #0e1f2c;
  font-size: 17px;
  font-weight: 600;
}

.file-upload__dragAndDropTextOr {
  color: #9b9b9b;
  font-weight: 600;
  font-size: 17px;
}


.file-upload__fileSelectButton {
  grid-area: select;
  min-width: 300px;
}

.hide {
  display: none;
}

.file-upload__upload-success {
  background: #c6da7c;
}

.file-upload__upload-failure {
  background: #d0021b;
  // background: #ff4d4d;
  color: white;
}

.file-upload__success-banner {
  display: flex;
  position: relative;
  font-size: 20px;
  padding: 10px 0px;
  justify-content: center;
  background-color: #c6da7c;
  width: 100%;
  border: white;
  border-radius: 10px;
  color: white;
}

.file-upload__error-banner {
  position: relative;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  background-color: $alert-red;
  width: 80%;
  color: white;
  border: white;
  border-radius: 10px;
}

.file-upload__close-dialog {
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  color: black;
  position: absolute;
  right: 50px;
}

.font-16px {
  font-size: 16px;
}

.functions-as-icons {
  margin: 20px 1rem;
}

.functions-as-icons__tooltip {
  visibility: hidden;
}

.functions-as-icons__tooltip:hover {  
  visibility: visible;
  min-width: 120px;
  height: auto;
}

.info-nugget-container {
  justify-content: space-between;
  max-width: 98%;
}

.life-to-date__note-for-legend {
  display: flex;
  justify-content: center;
  width: 100%;
}

.marketplace-reset-grid.mat-flat-button {
  height: 40px;
  margin-right: 20px;
}

button.mat-raised-button.secondary.marketplace-external-filter-button.growth-and-income.mat-primary {
  margin-left: 12px;
}

.mat-raised-button.marketplace-external-filter-button {
  height: 36px;
  min-width: 100px; 
  @media only screen and (max-width: 986px) {
    font-size: 12px;
    min-width: 80px;
  }
}

.marketplace-column-filter-button.mat-raised-button {
  height: 36px;
}

.marketplace-selected-column-filter {
  background-color: $luma-brand-green;
}

.mat-raised-button.marketplace-external-filter-button:disabled {
  color: $luma-link;
  border: 2px solid $luma-link;
}

// .mat-dialog-actions {
//   flex-direction: row-reverse;
// }

.mat-raised-button.ag-renderer-button {
  margin-top: -10px;
}

.mat-raised-button.button-cancel {
  margin: 1rem;
  margin-left: 0;
}

.mat-raised-button.button-submit {
  margin: 1rem;
  margin-left: 0;
}

.mat-slider.call-proximity-cell__proximity_slider {
  margin-top: 0.2rem;
  position: absolute;
}

.mat-slider.provided-value-cell__proximity_slider {
  margin-top: 0.2rem;
  position: absolute;
}

.no-documents {
  padding: 2px;
}

.portfolio-container.mat-card-content {
  margin: 0;
  padding: 0;
}

.product-data-table-title {
  flex-grow: 2;
}

.lm-product-type-tabs {
  ul {
    padding: 0;
    margin-block-end: 8px;
    padding-bottom: 2.5px;
  }
  li {
    background-color: white;
    margin: 10px 20px 0 0px;
    padding: 10px;
    display: inline;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    width: 200px;
    &.active {
      color: white;
      background-color: $luma-grid-header-gray;
    }
    div.product-type-content, a.product-type-content {
      width:200px;
      display: inline-block;
      text-align: center;
    }
    a.product-type-content {
      text-decoration: none;
      color: black;
    }
  }
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #4A5660;
  width: 100%;
  margin-bottom: 10px;
}

.product-details-container {
  height: auto;
  padding-bottom: 3rem;
}

.product-details-item {
  border-bottom: 1px solid #808080;
  line-height: 1.2rem;
  list-style: none;
  padding: 0.4rem;
  padding-left: 0.8rem;
  width: auto;
  min-width: 270px;
  overflow: auto;
}

.product-details-item-container {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.product-details-metrics {
  max-width: 46%;
  padding-top: 16px;
  text-align: center;
}

.product-details-stats-and-charts {
  display: flex;
}

.product-details-tab-container {
  height: 34rem;
  min-height: 34rem;
  margin-right: 16px;
  overflow: hidden;
}

.product-details-template-container {
  flex-grow: 2;
  margin-right: 1rem;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.product-search-results-table {
  margin-bottom: 1rem;
}

.product-search-results-table-header {
  height: 2.0rem;
}

.product-search-results-table-header.header-documentation {
  width: 110px;
}

.lm-dash-content-stack-container.mat-card {
  padding-left: 0;
  padding-right: 0;
}

.lm-dash-calendar-container.mat-card {}

.lm-dash-ag-grid-container.mat-card {}

.lm-logo-container {
  align-items: center;
  text-align: left;
  width: 100%;
  height: 100%;
  min-height: 48px;
  padding: 0 8px 0 8px;
  vertical-align: middle;
}

.lm-logo-svg {
  display: inline-block;
  font-size: inherit;
  overflow: visible;
  align-items: center;
  vertical-align: -0.125em;
}

.luma-app-login-input-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mat-card.menu-card__volume-by-issuer {
  padding-left: 0;
  padding-right: 0;
}

.mat-card.menu-card__volume-by-month {
  padding-left: 0;
  padding-right: 0;
}

// .mat-tab-body-wrapper {
//   max-height: 30rem;
// }


.mat-tab-fill-height .mat-tab-body-wrapper  {
  max-height: 100%;
}

.menu-card {
  height: 4.5em;
  overflow: hidden;

  &.mat-card {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .mat-card-title {
    color: rgba($pure-black, 0.75);
    font-size: 1.5em;
    font-weight: 500;
  }

  mat-card-title,
  mat-card-content {
    position: relative;
    text-align: right;
  }

  .mat-card-content {
    font-size: 12px;
    padding-left: 5em;
  }
}

.no-wrap {
  white-space: nowrap;
}

.no-zoom {
  overflow: hidden;
}

.order-account_file-item {
  border-radius: 8px;
  border-style: solid;
  border-color: #e6e6e6;
  border-width: 1px;

}

.order-account_medium-form-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #0e1f2c;
}

.order-account_delete-file {
  background: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border: white;
  border-radius: 10px;
  width: 2rem;
  height: 39px;
}


.order-account_blue-button {
  background-color: $luma-link;
  color: white;
}

.order-account_button-test {
  background-color: $luma-light-gray;
}



.order-approval_net-worth .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px 0px 5px 0px;
}

.order-approval_net-worth .mat-form-field-appearance-outline .mat-form-field-prefix
// .mat-form-field-appearance-outline .mat-form-field-suffix // todo: delete/cleanup if no one complains (check kyc?)
{
  top: 0px !important;
}

.order-approval_net-worth .mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element:disabled {
  color: black;
}

.order-approval_expandable-title {
  font-weight: 600;
}

.order-approval_table-emphasis {
  font-weight: 500;
}

.order-form_dialog-message {
  font-size: 16px;
  letter-spacing: .15px;
}

.order-form_dialog-message-container {
  text-align: center;
}

.order-form_inner-tabs .mat-tab-body-content {
  background-color: #FCFCFC
}

.position-fixed-right{
  position: fixed;
  right: 16px;
}

.product-details-template-container .mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

.spacer {
  flex: 1 1 auto;
}

.static-graph-image {
  margin: 1rem;

}

.static-metric-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.summary-header {
  line-height: 1.15;
  margin: 0.4rem;
  margin-left: 0;
  margin-top: 1rem;
  padding: 0.80rem;


}

.summary-text {
  margin-bottom: 2.2rem;
  margin-top: 1.2rem;
  padding: 0.80rem;

}

.tab-content {
  height: 30em;
  max-height: 31em;
}

.tab-content.ag-grid-tab-content {
  height: auto;
}

.tab-content.core-details-tab-content {}

.tab-content.documentation-tab-content {
  height: 30em;
}

.tab-content.email-tab-content {
  height: auto;
  padding-top: 0.4rem;
}

.mat-tab-group>.tab-content>li {
  list-style: none;
  line-height: 1.2rem;
  padding: 0.2rem;
}

.table-container {
  height: 25em;
  overflow: auto;
}

.square-button-with-icon {
  margin-right: 1.0rem !important;
  height: 24px;
  width: 24px;
  max-width: 24px;
  padding: 0 !important;
  padding-bottom: 4px;
  min-width: 0px !important;
}

.product-search-button {
  height: 28px;
  max-width: 28px;
  width: 28px;
}

.select-box-options {
  margin: 1rem;
  margin-top: 0;
}

.special-gray-line {
  display: inline-block;
  height: 1px;
  margin-top: 1rem;
  width: 100%;
}

.warning-modal-content-as-full-page {
  height: auto;
  line-height: 1.43;
  padding-top: 2rem;
  text-align: center;
}

.warning-yellow {
  margin: 1rem;
  margin-left: 0rem;
  text-align: center;
}

.watermark-holder__documents {
  height: 439px;
  width: 331px;
  padding-top: 36px;
  position: absolute;
  background-image: url('https://img.lumafintech.com/assets/documents_watermark.png');
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  z-index: -999999;
  top: 0;
  left: 15%;
}

.sso-required-page-container-wrapper {    
  text-align: center;
  margin-top: 5em;  
}

.sso-required-page-container {
  display: inline-block;
  border-radius: 4px;
  border: 0.1em solid $luma-brand-gray;
  padding: 1em;
  width: 46em;
  text-align: center;
  background-color: $luma-brand-yellow;
}

.sso-message {
  font-family: Montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  margin-left: 12px;
}

.centered-text-header {
  flex: 1;
  text-align: center;
  justify-content: center;
  & .ag-header-cell-text {
    flex: inherit;
    text-align: inherit;
    justify-content: inherit;
  }
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.no-border-radius-dialog .mat-dialog-container {
  border-radius: 0;
}

.summary-dialog {
  .mat-dialog-container {
    max-width: 62.5rem;
    min-width: 50vw;
    @media only screen and (max-width: 1080px) {
      max-width: 85vw;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100vw;
    }
  }
}

.responsive-dialog {
  & .mat-dialog-container {
    width: 90vw;
    height: 100vh;

    @media (max-width: 960px) {
      width: 100vw;
    }

    @media (min-width: 1080px) {
      width: 70vw;
    }

    @media (min-width: 1280px) {
      width: 60vw;
    }
  }
}

.dialog-900px {
  & .mat-dialog-container {
    width: min(100%, 900px);
    height: 100vh;
  }

  @media screen and (min-width: 900px) {
    width: 900px;
  }
}
.dialog-1400px {
  & .mat-dialog-container {
    width: min(100%, 1400px);
    height: 100vh;
  }

  @media screen and (min-width: 1400px) {
    width: 1400px;
  }
}

.responsive-dialog-compare {
  & .mat-dialog-container {
    width: 1200px;
    height: 100vh;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}

$mat-dialog-background: ("gray": #f4f5f7);



@mixin mat-dialog-background($class-name, $dialog-background-color) {
  $formatted-class-name: '.mat-dialog-background-#{$class-name}';
  #{if(&, '&.#{$formatted-class-name}', $formatted-class-name)} {
    & .mat-dialog-container {
      background-color: $dialog-background-color;
    }
  }
}

@each $name, $color in $mat-dialog-background {
  @include mat-dialog-background($name, $color);
}


.lm-box-shadow {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05), -2px -2px 4px 0 rgba(0, 0, 0, 0.05);
}

.luma-box-shadow {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}


.no-padding-mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0;
}

.mat-panel-header-description-right-aligned.mat-expansion-panel-header-description {
  flex-grow: 0;
  justify-content: flex-end;
}

.lm-table {
  border-collapse: collapse;
  &.mat-table {
    border-collapse: separate;
  }
  margin: 0 auto;
  font-size: 0.75rem;
  table-layout: fixed;

  thead {
    border-collapse: separate;
    th {
      background: $pure-white;
    }
  }

  & tr.mat-row {
    height: 2.25rem;
  }

  th, td {
    padding: 0.75rem;
    text-align: center;
  }

  .td-ta-r {
    text-align: right;
  }

  td {
    &.mat-cell:last-of-type {
      padding-right: 0.5rem;

    }

    input {
      background: $pure-white;
      height: 1.5rem;
      max-width: 6rem;
      border-radius: 2px;
      border: none;
      box-shadow: 0 0 1px rgba($pure-black, 0.8);
      text-align: right;
      width: 98%;

      &[type=number]::-webkit-inner-spin-button,
      &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  th {
    background: $luma-tbl-hd-bg;
    color: $luma-tbl-hd-fg;
    text-align: left;
    &.mat-header-cell {
      text-align: center;
      border-bottom: 0.4rem solid $luma-sys-slateblue;
    }
  }

  tr {
    background-color: $luma-tbl-bg-1;
  }

  tr:nth-child(even) {
    background: $luma-tbl-bg-2;
  }
}


// Luma Table - 2.0
.luma-table {
  border-collapse: collapse;
  &.left-align-first {
    tbody tr.mat-row td.mat-cell:first-of-type {
      text-align: left;
    }
  }
  &.mat-table {
    border-collapse: separate;
  }
  margin: 0 auto;
  font-size: 0.75rem;
  table-layout: fixed;

  thead {
    border-collapse: separate;
    th {
      background: #7c8e99; // TODO
      color: white; // todo
      border-top: none;
      &:not(:last-child)::before {
        content: "";
        position: absolute;
        right: 0;
        top: 12.5%;
        width: 1px;
        height: 75%;
        background: #e8e8e8;
        opacity: 0.25;
        border-radius: 2px;
      }
    }

    th:first-child {
      border-top-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
    }
  }

  & tr.mat-row {
    height: 0;
  }

  & tr.data-row {
    height: 2.5rem;
    &.misfit {
      background-color: $info-grey;
    }

    &.misfit-title{
      height: 2em;
    }
  }

  .mat-table tbody {
    overflow: hidden;
  }

  th, td {
    padding: 0.75rem;
    text-align: center;
    font-size: 12px;
  }

  .td-ta-r {
    text-align: right;
  }

  td {
    &.mat-cell:last-of-type {
      padding-right: 0.75rem;
    }

    input {
      background: $pure-white;
      height: 1.5rem;
      max-width: 6rem;
      border-radius: 2px;
      border: 1px solid black;
      box-shadow: 0 0 1px rgba($pure-black, 0.8);
      text-align: right;
      width: 100%;
      outline-color: $link-blue;

      &[type=number]::-webkit-inner-spin-button,
      &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.mat-cell.td-no-padding {
      &,
      &:first-of-type {
        padding: 0;
      }
      .td-no-overflow {
        overflow: hidden;
      }
      &.underlier-title {
        background-color: $white;
        text-align: left;
        .td-no-overflow {
          padding-left: 12px;
        }
      }
    }
  }

  th {
    background: #E8E8E8; // TODO
    color: #848484;
    text-align: left;
    border-top: white 4px solid;
    &.mat-header-cell {
      text-align: center;
    }
  }

  tr {
    background-color: #d1d7da; // TODO
  }

  .misfit-icon {
    float: left;
    margin-left: 10px;
    margin-right: 4px;
  }
  

  // Remove padding from first (left-most) cells
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 2px;
  }

  // Remove padding from last (right-most) cells
  th.mat-header-cell:last-of-type,
    // thead selector needed for inputs on annuity tables (can refactor later) - Zach Fowles
  thead td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 2px;
  }
}


.lm-modal {
  &-header {
    width: 100%;
    background-color: $luma-sys-slateblue;
    color: $pure-white;
  }

  &-footer {
    width: 100%;
    background: $pure-white;
    box-shadow: 0px -2px 5px rgba(50, 50, 50, 0.5);
  }
}

.flex-hidden {
  visibility: collapse;
}

app-missing-market-data-grid {
  .ag-grid-container{
    margin-left: 0;
    margin-right: 0;
  }
}

.highcharts-tooltip-container {
  z-index: 9999 !important;
}
