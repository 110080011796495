.details__panel {
  width: 840px;

  &.mat-expansion-panel {
    margin-bottom: 24px;
  }

  .mat-expansion-panel-header {
    padding: 0 15px;
    height: 48px !important;
  }

  .mat-expansion-panel-header-title {
    color: black!important;
    margin: 0;
    border-bottom: none !important;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
    border-top: 2px solid rgba(14, 31, 44, 0.75);
  }

  .mat-expansion-indicator::after{
    border-width: 0 4px 4px 0 !important;
    padding: 4px !important;
    color: #b6ca6e;
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
  }

  .container {
    background: #fff;
    font-size: 14px;
    padding: 8px;
  }

  .double-col {
    // border: 1px solid;
    width: 100%;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05), -2px -2px 4px 0 rgba(0, 0, 0, 0.05);
    .label {
      width: 23%;
    }
  }

  .col {
    // border: 1px solid;
    width: calc(50% - 4px);
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05), -2px -2px 4px 0 rgba(0, 0, 0, 0.05);
  }

  .row {
    padding: 8px 16px;
    align-items: center;
    display: flex;
    height: 32px;
    line-height: 1.25;
    border-bottom: 1px solid #f4f4f5;

    .value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &> div {
      display: inline-block;
    }

    // TO-DO fix alternate pattern 
    // &:nth-child(odd) {
    //   background: #f9f9fa;
    // }

    &.date-table-row {
      height: auto;

      .label {
        width: 20%;
      }

      .single-value {
        width: 80%;
        padding-left: 114px;
      }
    }
  }

  .label {
    width: 50%;
    flex-shrink: 0;
    color: #888888;
    margin-right: 24px;
    // border: 1px solid yellow;
  }
}

.hint__msg {
  color: $luma-brand-orange;
  font-size: 16px;
  margin-right: 8px;
}

.error__msg {
  color: $luma-alert-red;
  font-size: 16px;
  margin-right: 8px;
}

.success__msg {
  color: $luma-alert-green;
  font-size: 16px;
  margin-right: 8px;
}

.gwim-tooltip {
  white-space: pre-line;
  line-height: 2;
  letter-spacing: .15px;
}

.row.multi-row {
  height: auto;

  .value {
    height: 80px;
    flex-wrap: wrap;
    align-content: space-around;

    div {
      flex: 0 0 100%;
    }
  }
}
